<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <loading v-if="loading" />
        <div v-if="!loading">
          <div v-if="announcements.length === 0" class="">
            <p class="mb-1">Keep an eye out here for new updates!</p>
          </div>
          <div v-else>
            <div class="row mb-2">
              <div class="col">
                <p
                  v-for="(announcement, index) in announcements"
                  style="
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                  :class="
                    index !== announcements.length - 1
                      ? 'border-bottom-1 pb-2 mb-2'
                      : ''
                  "
                  class="small mt-0"
                >
                  <router-link
                    class="text-decoration-none"
                    :to="'/announcements/' + announcement.id"
                  >
                    <i class="far fa-exclamation-circle text-primary me-2"></i
                    >{{ announcement.title }}
                  </router-link>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col my-auto">
                    <button
                      class="btn btn-sm btn-light"
                      @click="fetchAnnouncements(pagination.prev_page_url)"
                      :disabled="!pagination.prev_page_url"
                    >
                      Previous
                    </button>
                  </div>
                  <div class="col my-auto text-end">
                    <button
                      class="btn btn-sm btn-light cursor-pointer"
                      @click="fetchAnnouncements(pagination.next_page_url)"
                      :disabled="!pagination.next_page_url"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  components: {},
  data() {
    return {
      loading: true,
      announcements: [],
      pagination: {},
    };
  },
  methods: {
    async fetchAnnouncements(paginationUrl) {
      this.loading = true;
      var url = paginationUrl
        ? paginationUrl
        : process.env.VUE_APP_API_URL + "/announcements";

      const { data } = await this.$axios.get(url);
      this.pagination = data;
      this.announcements = data.data;
      this.loading = false;
    },
  },
  computed: {},
  filters: {},
  created() {
    this.fetchAnnouncements();
  },
};
</script>

<style>
</style>
