<template>
  <div class="dashboardArea">
    <div class="container-fluid DASHBOARD-CLASS-REF-FOR-PDF-GENERATOR">
      <!--  -->
      <div class="row mb-4">
        <div class="col">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <h3 class="text-primary fw-bold mb-0">
                Hello {{ user.first_name }}
              </h3>
              <p class="">
                Welcome back to Swandoola! We are here to help you, please drop
                the team a message at
                <a href="mailto:support@swandoola.com">support@swandoola.com</a>
                and we'll be happy to assist with any queries you have.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <h5 class="text-primary fw-bold mb-3">Recent order history</h5>

      <line-chart></line-chart>

      <!--  -->
      <div class="row mt-4">
        <div class="col col-md-8 pe-0">
          <div
            v-if="
              user.clinic.billing_subscription.billing_product.hierarchy === 0
            "
          >
            <upgrade :user="user"></upgrade>
          </div>
          <div v-else>
            <h5 class="text-primary fw-bold mb-3">Recent Activity</h5>
            <notifications
              :user="user"
              id="dashboard-notifications"
            ></notifications>
          </div>
        </div>

        <!-- SIDEBAR -->
        <div class="col col-md-4 mt-3 mt-md-0">
          <h5 class="text-primary fw-bold mb-3">Swandoola Updates</h5>
          <announcements :user="user"></announcements>
          <!-- <upcoming-webinars></upcoming-webinars> -->
          <!-- <community :user="user"></community> -->
        </div>
      </div>

      <!--  -->
    </div>
  </div>
</template>

<script>
import Upgrade from "./partials/Upgrade";
import Community from "./partials/Community";
import Notifications from "./partials/Notifications";
import UpcomingWebinars from "./partials/UpcomingWebinars";
import Announcements from "@/views/areas/dashboard/partials/Announcements";
import LineChart from "./partials/LineChart";

export default {
  props: ["contactsCount", "resourcesCount", "team"],
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    sendalert() {
      var data = {
        color: "danger",
        message:
          "some message lorem Lorem ipsum dolor sit amet consectetur, adipisicing elit. Possimus dignissimos repellendus quas officia minus, inventore impedit, provident aut dolores ipsam, praesentium rem ut nam repudiandae vero iusto sunt? Obcaecati, tempore!",
      };
      this.$EventBus.$emit("alert", data);
    },
    goToOrders() {
      return this.$router.push("/orders");
    },
    goToContacts() {
      return this.$router.push("/clients");
    },
    goToFriends() {
      return this.$router.push("/community/friends");
    },
    goToFeedback() {
      return this.$router.push("/feedback");
    },
    goToResources() {
      return this.$router.push("/resources");
    },
  },
  data() {
    return {
      hideMe: false,
      loadingChart: true,
    };
  },
  components: {
    Announcements,
    Community,
    Upgrade,
    UpcomingWebinars,
    Notifications,
    LineChart,
  },
  created() {
    this.$EventBus.$on("dashChartBusy", function (e) {
      this.loadingChart = e;
    });
  },
  mounted() {
    this.$stash.clientInView = null;
    $(document).ready(function () {
      $(".hoverOrder")
        .on("mouseover", function () {
          $(this).addClass("shadow-sm");
        })
        .on("mouseout", function () {
          $(this).removeClass("shadow-sm");
        });
    });
  },
};
</script>

<style>
@media (max-width: 576px) {
  #dashboard-notifications {
    display: none;
  }
}
@media (min-width: 576px) {
  #dashboard-notifications {
    display: block;
  }
}
</style>
