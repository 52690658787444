import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      pinkGradient: null,
      gradient2: null,
      labels: [],
      totals: [],
      totals_last: [],
    };
  },
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async fetchOrders() {
      this.$EventBus.$emit("dashChartBusy", true);
      const { data } = await this.$axios.get(process.env.VUE_APP_API_URL + "/orders/dashboard");
      this.labels = data.labels;
      this.totals = data.data;
      this.totals_last = data.data_last;
      this.$EventBus.$emit("dashChartBusy", false);
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              label: "This Year",
              borderColor: "#06010e",
              pointBackgroundColor: "white",
              borderWidth: 1,
              pointBorderColor: "white",
              backgroundColor: this.blueGradient,
              data: this.totals,
            },
            {
              label: "Last Year",
              borderColor: "#6c4a9e",
              pointBackgroundColor: "white",
              borderWidth: 1,
              pointBorderColor: "white",
              backgroundColor: this.pinkGradient,
              data: this.totals_last,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value.toLocaleString("en-GB", { style: "currency", currency: "GBP" });
                  },
                },
              },
            ],
          },
        }
      );
    },
  },
  created() {
    this.fetchOrders();
  },
  mounted() {
    this.pinkGradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.blueGradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    this.pinkGradient.addColorStop(0, "rgba(240, 13, 118, 0.75)");
    this.pinkGradient.addColorStop(0.5, "rgba(240, 13, 118, 0.25)");
    this.pinkGradient.addColorStop(1, "rgba(240, 13, 118, 0)");

    this.blueGradient.addColorStop(0, "rgba(108, 74, 158, 0.9)");
    this.blueGradient.addColorStop(0.5, "rgba(108, 74, 158, 0.25)");
    this.blueGradient.addColorStop(1, "rgba(108, 74, 158, 0)");
  },
};
