<template>
  <div class="row mb-3">
    <div class="col">
      <loading v-if="loadingNotifications" />
      <div v-if="!loadingNotifications">
        <table class="table table-striped mb-0">
          <tbody>
            <tr
              v-for="(notification, index) in notifications"
              :key="notification.id"
            >
              <td
                class="text-nowrap"
                :style="index === 0 ? 'border-top: 0px;' : ''"
              >
                <i class="far text-primary me-2" :class="notification.icon"></i>
                {{ notification.created_at | relative }}
              </td>
              <td :style="index === 0 ? 'border-top: 0px;' : ''">
                <div style="display: table; table-layout: fixed; width: 100%">
                  <span
                    style="
                      display: table-cell;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    {{ notification.body }}
                  </span>
                </div>
              </td>
              <td :style="index === 0 ? 'border-top: 0px;' : ''">
                <router-link
                  :to="notification.action_url"
                  class="btn btn-sm btn-outline-primary text-nowrap w-100"
                  v-if="notification.action_text"
                  >{{ notification.action_text }}</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      loadingNotifications: true,
      notifications: [],
    };
  },
  methods: {
    async fetchNotifications() {
      this.loadingNotifications = true;

      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/notifications"
      );

      this.notifications = data.notifications.slice(0, 15);
      this.loadingNotifications = false;
    },
  },
  computed: {},
  filters: {
    relative(date) {
      return moment(date).local().locale("en-short").fromNow();
    },
  },
  created() {
    this.fetchNotifications();
  },
};
</script>

<style>
</style>
