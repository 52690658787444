<template>
  <div class="row mb-4">
    <div class="col">
      <div class="card border-0 shadow-sm">
        <div class="card-header bg-primary text-white border-0">
          <div class="row">
            <div class="col-auto my-auto">
              <span class="fw-bold">Webinars</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <div
                class="card text-center border-0 bg-light shadow-sm"
                @click="goToWebinars"
              >
                <div
                  class="card-body cursor-pointer"
                  style="color: black !important"
                >
                  <p>
                    <img
                      src="@/assets/images/people/jo_gamble.jpg"
                      class="rounded-circle"
                      height="100px"
                    />
                  </p>
                  <p class="fw-bold text-uppercase mt-2 mb-0">
                    Supporting Practitioners with Innovative Solutions
                  </p>
                  <p class="mt-1">Jo Gamble & Sue Camp</p>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row" v-if="upcomingWebinars.length > 0"
               style="overflow-y: scroll; height: 312px;">
            <div class="col-12 col-sm-6 mb-3" v-for="w in upcomingWebinars">
              <div class="card text-center border-0 bg-light shadow-sm" @click="goToWebinar(w.id)">
                <div class="card-body cursor-pointer">
                  <p><i class="text-primary far fa-presentation fa-2x"></i></p>
                  <p class="fw-bold text-uppercase mt-2 mb-0">{{ w.event.title }}</p>
                  <p class="mt-2 text-capitalize"><small>{{ w.event.start | formatDate }}</small></p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col text-center">
              <p>No upcoming webinars.</p>
              <p>We'll keep you posted!</p>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webinars: []
    };
  },
  methods: {
    async fetchWebinars() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/webinars/api"
      );

      this.webinars = data;
    },
    goToWebinars() {
      this.$router.push("/webinars");
    }
  },
  computed: {
    upcomingWebinars() {
      return this.webinars.filter(function(el) {
        return moment(el.event.start) > moment.now();
      });
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    }
  },
  created() {
    this.fetchWebinars();
  }
};
</script>

<style>
</style>
