<template>
  <div class="card border-0 shadow-sm">
    <div class="card-header bg-primary text-white border-0">
      <div class="row">
        <div class="col-auto my-auto">
          <span class="fw-bold">Upgrade</span>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <pricing-table :user="user" :period="'annually'"></pricing-table>
    </div>
  </div>
</template>

<script>
import PricingTable from "../../billing/partials/PricingTable";
export default {
  components: {PricingTable},
  props: [
    "user",
  ],
  data() {
    return {
    };
  },
  methods: {
    goTo(url){
      window.location = url;
    }
  },
  computed: {

  },
  mounted() {
  }
};
</script>

<style>
  .btn-black{
    color: black;
  }
  .btn-black:hover{
    color: white;
  }
</style>