<template>
  <div class="row mb-3">
    <div class="col">
      <div class="card border-0 shadow-sm">
        <div class="card-header bg-primary text-white border-0">
          <div class="row">
            <div class="col-auto my-auto">
              <span class="fw-bold">Community</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col text-center">
              <a href="/community">
                <h1><i class="far fa-globe-europe text-primary fa-2x"></i></h1>
                <h5>Visit the Community</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {};
  },
  methods: {},
  computed: {},
  filters: {},
  mounted() {}
};
</script>

<style>
</style>